<template>
    <div class="container mx-auto max-w-[1185px] flex flex-col justify-between">
        <header class="flex justify-center pt-9">
            <LogoComponent/>
        </header>
        <!--        <nav>-->
        <!--            <MainMenu/>-->
        <!--        </nav>-->
        <main class="mb-auto">
            <MainContent/>
        </main>
        <footer class="text-center">
            Copyright 2021 by KOT Partners. Все права защищены
        </footer>
    </div>
</template>

<script>
import LogoComponent from "./LogoComponent.vue";
import MainContent from "./MainContent.vue";
// import MainMenu from "./MainMenu.vue";

export default {
    components: {
        LogoComponent,
        // MainMenu,
        MainContent
    },
    name: "MainIndex",
};
</script>
<style>
body {
    background-image: url("/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
</style>