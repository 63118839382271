<template>
    <div class="flex h-60">
        <img src="/logo.svg" alt=""/>
    </div>
</template>

<script>
export default {
    name: "logoComponent",
};
</script>