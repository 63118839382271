<template>
    <button
        class="px-8 bg-indigo-500 hover:bg-indigo-900 font-bold p-2 uppercase">
        <slot/>
    </button>
</template>

<script>
export default {
    name: "BtnComponent",
};
</script>
