<template>
    <div class="h-[256px] rounded-3xl m-2 bg-687ED678">
        <div class="h-2/3 flex justify-center items-center">
            <img :src="item.image" size="70" alt=""/>
        </div>
        <div class="text-center text-2xl px-2">{{ item.title }}</div>
    </div>
</template>

<script>
export default {
    name: "CardComponent",
    props: ["item"],
};
</script>