<template>
    <MainIndex class="text-white"/>
</template>

<script>
import MainIndex from "./components/MainIndex.vue";

export default {
    components: {MainIndex},
    name: "App",
};
</script>
