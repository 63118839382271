<template>
    <div>
        <redirect-component/>
<!--        <LoginComponent v-if="$kot.login"/>-->
<!--        <RegistrationComponent v-else/>-->
    </div>
</template>

<script>
// import LoginComponent from "./LoginComponent.vue";
// import RegistrationComponent from "./RegistrationComponent.vue";
import RedirectComponent from "./RedirectComponent";

export default {
    name: "FormCo,ponent",
    components: {
        RedirectComponent,
        // LoginComponent,
        // RegistrationComponent
    },
};
</script>
